<template>
  <footer>
    <div class="container-fluid p-md-lg">
        <div class="row" style="display: none">
            <div class="col-12 build-info mt-3">
                <span>Revision - 957fa35 del 5/22/2020 10:39:53 AM</span>
            </div>
        </div>
        <div class="row logo-container sm-center">
            <div class="col-12 col-sm-5 my-auto">
                <a href="http://www.camcom.gov.it" rel="noopener" target="_blank">
                    <img class="logo-camcom img-fluid" src="@/assets/svg/cdci.svg" alt="CAMERE DI COMMERCIO D'ITALIA">
                </a>
            </div>
            <div class=".d-none .d-sm-block col-2"></div>
            <div class="col-12 col-sm-5 my-auto footer-ic">
            <p class="text-right" style="display: inline-block;">{{ $t("msg.servizio-realizzato-da") }}   </p>
            <a href="https://www.infocamere.it" rel="noopener" target="_blank">
                    <img class="logo-ic" src="@/assets/images/infocamere.png" alt="Servizio realizzato da Infocamere">
                </a>
            </div>
        </div>
        <hr>
        <div class="row info-container">
            <div class="col-12 col-sm-3 note-legali ">
                <div class="text-center text-md-left">
                    <a href="https://informative.infocamere.it/depositi-e-istanze-registro-imprese" class="mano" rel="noopener" target="_blank">{{ $t("msg.note-legali-policy")}}</a>
                </div>
                <div class="text-center text-md-left">
                    <a href="https://informative.infocamere.it/informativa-pratiche-CCIAA" class="mano" rel="noopener" target="_blank">{{ $t("msg.pratiche-policy")}}</a>
                </div>
            </div>
            <div class="col-12 col-sm-9 dati-ic  text-center text-md-right" style="font-size:0.75rem">
                {{$t("msg.footer-address")}}
            </div>
        </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
  props: {
  }
}
</script>

<style lang="scss">
  @import "@/assets/sass/dire.scss";
</style>
