<template>
  <div id="app" v-on:mousmove="checkStatusNavbar">
    <header-component/>
    <content-component/>
    <footer-component />
  </div>
</template>

<script>
import HeaderComponent from './components/HeaderComponent.vue'
import ContentComponent from './components/ContentComponent.vue'
import FooterComponent from './components/FooterComponent.vue'
import 'jquery/src/jquery.js'
import 'bootstrap-italia';

export default {
  name: 'App',
  components: {
    HeaderComponent,
    ContentComponent,
    FooterComponent
  },
      methods: {
      checkStatusNavbar: function () {
        setTimeout(() => {
          if (document.getElementById('navbarSupportedContent')) {
            if (!document.getElementById('navbarSupportedContent').classList.contains('show')) {
              document.getElementById('navbarSupportedContent').style.display = 'none';
            }
          }
        }, 400);

      }
    }
}
</script>

<style>

@font-face {
  font-family: 'Titillium Web';
  src:  
   url(./assets/fonts/Titillium_Web/TitilliumWeb-Regular.ttf) format("truetype"),
   url(./assets/fonts/Titillium_Web/TitilliumWeb-Regular.woff) format("woff"),
   url(./assets/fonts/Titillium_Web/TitilliumWeb-Regular.woff2) format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Titillium Web';
  src:  
   url(./assets/fonts/Titillium_Web/TitilliumWeb-Light.ttf) format("truetype"),
   url(./assets/fonts/Titillium_Web/TitilliumWeb-Light.woff) format("woff"),
   url(./assets/fonts/Titillium_Web/TitilliumWeb-Light.woff2) format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Titillium Web';
  src:  
   url(./assets/fonts/Titillium_Web/TitilliumWeb-SemiBold.ttf) format("truetype"),
   url(./assets/fonts/Titillium_Web/TitilliumWeb-SemiBold.woff) format("woff"),
   url(./assets/fonts/Titillium_Web/TitilliumWeb-SemiBold.woff2) format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Titillium Web';
  src:  
   url(./assets/fonts/Titillium_Web/TitilliumWeb-Bold.ttf) format("truetype"),
   url(./assets/fonts/Titillium_Web/TitilliumWeb-Bold.woff) format("woff"),
   url(./assets/fonts/Titillium_Web/TitilliumWeb-Bold.woff2) format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Titillium Web';
  src:  
   url(./assets/fonts/Titillium_Web/TitilliumWeb-ExtraLight.ttf) format("truetype"),
   url(./assets/fonts/Titillium_Web/TitilliumWeb-ExtraLight.woff) format("woff"),
   url(./assets/fonts/Titillium_Web/TitilliumWeb-ExtraLight.woff2) format("woff2");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Titillium Web';
  src:  
   url(./assets/fonts/Titillium_Web/TitilliumWeb-Black.ttf) format("truetype"),
   url(./assets/fonts/Titillium_Web/TitilliumWeb-Black.woff) format("woff"),
   url(./assets/fonts/Titillium_Web/TitilliumWeb-Black.woff2) format("woff2");
  font-weight: 900;
  font-style: normal;
}

</style>
