<template>
  <div>
    <div id="myHeader"
      class="navbar d-block"
      style="
        background: #008fbf;
        border-radius: 0;
        margin-bottom: 0 !important;
        width: 100% !important;
          z-index: 9999999;
      "
    >
      <div >
        <div class="header-block d-flex justify-content-between">

          <div class="d-lg-none d-inline-flex h-100">
              <div class="burger centered" style="margin-top:5px;">
                <button
                  class="custom-navbar-toggler mt-2"
                  type="button"
                  data-toggle="collapse"
                  v-on:click="checkStatusNavbar"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  id="navbarSupportedContentBtn"
                  aria-label="Toggle navigation"
                >
                  <svg class="icon icon-light">
                    <use xlink:href="@/assets/svg/sprite.svg#it-burger"></use>
                  </svg>
                </button>
              </div>
            <div class="centered">
              <a href="">
                <div>
                  <img
                    class="dire-logo"
                    src="@/assets/icons/android-chrome-192x192.png"
                  />
                  <img
                    class="icona-logo-claim"
                    src="@/assets/svg/logoClaim.svg"
                  />
                </div>
              </a>
            </div>
          </div>

          <div class="header-logo d-none d-lg-block">
            <a href="">
              <div class="centered">
                <img
                  class="dire-logo"
                  src="@/assets/icons/android-chrome-192x192.png"
                />
                <img
                  class="icona-logo-claim"
                  src="@/assets/svg/logoClaim.svg"
                />
              </div>
            </a>
          </div>

          <div class="header-menu d-none d-lg-block">
            <ul class="navbar-header d-flex h-100">
              <li class="horizontal-navbar link centered margin-navbar-menu">
                <a class="text-menu" href="#cos-e-link">{{ $t("msg.cos-e") }}</a>
              </li>
              <li class="horizontal-navbar link centered margin-navbar-menu">
                <a class="text-menu" href="#cosa-fare-link2">{{
                  $t("msg.cosa-fare")
                }}</a>
              </li>
              <li class="horizontal-navbar link centered margin-navbar-menu">
                <a class="text-menu" href="#assistenza">{{
                  $t("msg.assistenza")
                }}</a>
              </li>
              <li class="horizontal-navbar link centered margin-navbar-menu">
                <a
                  class="text-menu"
                  href="https://www.registroimprese.it/registrazione"
                  >{{ $t("msg.registrati") }}</a
                >
              </li>
              <li class="horizontal-navbar link centered margin-navbar-menu d-none">
                <a class="text-menu" href="javascript:void(0)" v-on:click="changeLang()">{{ getLangMsg() }}</a>
              </li>
              <li class="horizontal-navbar">
                <div style= "margin-top: 20px; margin-left:20px">
                <a v-bind:href="getUrlDireWeb()"><button class="btn-navbar"><b>{{ $t("msg.accedi") }}</b></button></a>
                </div>
              </li>
            </ul>
          </div>
          <div class="d-xs-block d-sm-block d-md-block d-lg-none mt-4 mr-2">
            <a v-bind:href="getUrlDireWeb()"
              ><button class="btn-navbar mt-1"><b>{{ $t("msg.accedi") }}</b></button></a
            >
          </div>
        </div>
      </div>
    </div>

    <div id="mobile-menu" class="d-lg-none">
      <ul class="navbar-nav ml-auto mt-lg-0">
        <li class="nav-item active">
          <div class="navbar-item-container" v-on:click="checkStatusNavbar">
            <a
              class="t-dec-none it-header-slim-wrapper-content d-flex"
              href="#cos-e-link"
            >
              <div class="header-text" style="margin: 1rem">
                {{ $t("msg.cos-e") }}
              </div>
            </a>
          </div>
        </li>
        <li class="nav-item active">
          <div class="navbar-item-container d-md-none d-lg-block" v-on:click="checkStatusNavbar">
            <a
              class="t-dec-none it-header-slim-wrapper-content d-flex"
              href="#cosa-fare-link"
            >
              <div class="header-text" style="margin: 1rem">
                {{ $t("msg.cosa-fare") }}
              </div>
            </a>
          </div>

          <div class="navbar-item-container d-none d-md-block d-lg-none" v-on:click="checkStatusNavbar">
            <a
              class="t-dec-none it-header-slim-wrapper-content d-flex"
              href="#cosa-fare-link3"
            >
              <div class="header-text" style="margin: 1rem">
                {{ $t("msg.cosa-fare") }}
              </div>
            </a>
          </div>
        </li>
        <li class="nav-item active">
          <div class="navbar-item-container d-md-none d-lg-block" v-on:click="checkStatusNavbar">
            <a
              class="t-dec-none it-header-slim-wrapper-content d-flex"
              href="#assistenza2"
            >
              <div class="header-text" style="margin: 1rem">
                {{ $t("msg.assistenza") }}
              </div>
            </a>
          </div>
          <div class="navbar-item-container d-none d-md-block d-lg-none" v-on:click="checkStatusNavbar">
            <a
              class="t-dec-none it-header-slim-wrapper-content d-flex"
              href="#assistenza3"
            >
              <div class="header-text" style="margin: 1rem">
                {{ $t("msg.assistenza") }}
              </div>
            </a>
          </div>
        </li>
        <li class="nav-item active">
          <div class="navbar-item-container" v-on:click="checkStatusNavbar">
            <a
              class="t-dec-none it-header-slim-wrapper-content d-flex"
              href="https://www.registroimprese.it/registrazione"
            >
              <div class="header-text" style="margin: 1rem">
                {{ $t("msg.registrati") }}
              </div>
            </a>
          </div>
        </li>
        <!--
        <li class="nav-item active">
          <div class="navbar-item-container" v-on:click="checkStatusNavbar">
            <a
              class="t-dec-none it-header-slim-wrapper-content d-flex" 
              href="javascript:void(0)"
               v-on:click="changeLang()"
            >
              <div class="header-text" style="margin: 1rem">
                {{ getLangMsg() }}
              </div>
            </a>
          </div>
        </li>-->
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  name: "HeaderComponent",
  props: {},
  created(){
    window.addEventListener('scroll', this.myFunction);
  },
  data(){
      return{
        url_direWeb : process.env.VUE_APP_I18N_URL_DIRE_WEB,
      };
    },
  methods: {
    changeLang: function(){
      if (this.$i18n.locale=="it"){
        this.setLang("de");
      }
      else {
        this.setLang("it");
      }
    },
    getLangMsg: function(){
      if (this.$i18n.locale=="it"){
        return "DE";
      }
      else {
        return "ITA";
      }
    },
    getUrlDireWeb: function(){
      return process.env.VUE_APP_I18N_URL_DIRE_WEB;
    },
    setLang: function(lang){
      this.$i18n.locale = lang;
      document.cookie = "i18nCode" + "=" + lang + ";";
    },
    checkStatusNavbar: function () {
      setTimeout(() => {
        if (document.getElementById("mobile-menu")) {
          if (
            document.getElementById("mobile-menu").classList.contains("show")
          ) {
            document.getElementById("mobile-menu").classList.remove("show");
            document.getElementById("mobile-menu").classList.add("none");
            document.getElementById("mobile-menu").style.display = "none";
            console.log("1");
          } else {
            document.getElementById("mobile-menu").classList.remove("none");
            document.getElementById("mobile-menu").classList.add("show");
            document.getElementById("mobile-menu").style.display = "show";
            console.log("2");
          }
        }
      }, 400);
    },
    myFunction : function() {
      // Get the header
    var header = document.getElementById("myHeader");
    // Get the offset position of the navbar
    var sticky = header.offsetTop;
  if (window.pageYOffset > sticky) {
    header.classList.add("sticky");
  } else {
    header.classList.remove("sticky");
  }
}
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/dire.scss";

</style>
